<template>
    <div>
        <b-tab
            :title="$t(translationPath + 'tab_title_asset_overview')"
            :active="$route.name === 'account-plan-assets-overview'"
            @click="clickHandler({ name: 'account-plan-assets-overview' })"
        >
            <slot name="account-plan-assets-overview"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_asset_values')"
          :active="$route.name === 'account-plan-assets-values'"
          @click="clickHandler({ name: 'account-plan-assets-values' })"
        >
          <slot name="account-plan-assets-values"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_update_values')"
          :active="$route.name === 'account-plan-assets-update-values'"
          @click="clickHandler({ name: 'account-plan-assets-update-values' })"
        >
          <slot name="account-plan-assets-update-values"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_misc')"
          :active="$route.name === 'account-plan-assets-misc'"
          @click="clickHandler({ name: 'account-plan-assets-misc' })"
        >
          <slot name="account-plan-assets-misc"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_asset_categorize')"
            :active="$route.name === 'account-plan-assets-categorization'"
            @click="clickHandler({ name: 'account-plan-assets-categorization' })"
        >
            <slot name="account-plan-assets-categorization"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_asset_classes')"
            :active="$route.name === 'account-plan-assets-allocation'"
            @click="clickHandler({ name: 'account-plan-assets-allocation' })"
        >
            <slot name="account-plan-assets-allocation"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_four_bucket_settings')"
            :active="$route.name === 'account-plan-assets-four-buckets'"
            @click="clickHandler({ name: 'account-plan-assets-four-buckets' })"
        >
            <slot name="account-plan-assets-four-buckets"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_match_to_globals')"
            :active="$route.name === 'account-plan-assets-match-globals'"
            @click="clickHandler({ name: 'account-plan-assets-match-globals' })"
        >
            <slot name="account-plan-assets-match-globals"></slot>
        </b-tab>
        <b-tab
               :title="$t(translationPath + 'tab_title_account_ownership')"
               :active="$route.name === 'account-plan-assets-ownership'"
               @click="clickHandler({ name: 'account-plan-assets-ownership' })"
        >
          <slot name="account-plan-ownership-global"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_stats')"
          :active="$route.name === 'account-plan-assets-stats'"
          @click="clickHandler({ name: 'account-plan-assets-stats' })"
        >
          <slot name="account-plan-stats-assets"></slot>
        </b-tab>
        <b-tab v-if="checkDev"
            :title="$t(translationPath + 'tab_title_tink_autoimport')"
            :active="$route.name === 'account-plan-assets-tink'"
            @click="clickHandler({ name: 'account-plan-assets-tink' })"
        >
            <slot name="account-plan-assets-tink"></slot>
        </b-tab>
    </div>
</template>

<script>
export default {
  name: 'AssetsMenu',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  },
  computed: {
    checkDev () {
      return process.env.VUE_APP_NODE_ENV === 'development'
    }
  },
  methods: {
    clickHandler ({ name }) {
      if (this.$route.name === name) return true
      this.$router.push({ name })
    }
  }
}
</script>
