<template>
  <b-container class="component-account-plan-v2-ownership-table">
    <b-row>
      <b-col cols="12" class="px-0">

        <div v-if="this.persons.length===0"
             class="alert alert-warning col-md-8"
             v-html="this.$t(translationPath + 'no_persons_in_household')">
        </div>
        <b-table class="spirecta-simple-table account-plan-v2-ownership-table px-0 mx-0" show-empty hover responsive striped
          :fields="fields"
          :items="items"
          :busy="busy"
          :tbody-tr-class="rowClass"
          :filter="accountsFilter"
          :filter-included-fields="['title','title_with_code']"
          :emptyFilteredText ="$t('common.no_filter_result')"
           v-if="this.persons.length>0"
        >

          <!--LOADER-->
          <template v-slot:table-busy>
            <loader />
          </template>

          <!-- CUSTOM SLOT: FILTER -->
          <template #top-row>
            <td :colspan="fields.length">
              <div class="d-flex align-items-center">
                <i class="fa fa-search text-secondary"></i>
                <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
              </div>
            </td>
          </template>

          <!--Custom slots--->

          <!-- PERSONS -->
          <template v-for="person in persons" v-slot:[`cell(${person.key})`]="row">
              <!--<b-form-input v-model.number="row.item[person.key]" type="number" min="0" max="100" />-->
            <custom-number-input
              :key="`form-input${person.key}`"
              v-model="row.item[person.key]"
              :precision="0"
              :min="0"
              :max="100"
            />
          </template>
          <template v-slot:cell(total)="row">
            {{ rowTotal(row.item) }} %
          </template>

        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="items.length > 0">
      <b-col class="text-right">
        <b-button @click="saveHandler" variant="primary">{{ $t('common.save')}}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import CustomNumberInput from '@/components/common/CustomNumberInput'

export default {
  name: 'AccountsOwnershipTable',
  components: { Loader, CustomNumberInput },
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    busy: {
      type: Boolean,
      default: true
    },
    accountType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      translationPath: 'reports.other.account_plan.',
      persons: [],
      items: [],
      accountsFilter: ''
    }
  },
  async created () {
    try {
      const response = await this.fetchHouseHolds()
      this.persons = response.map((item) => {
        const fullName = item.firstname + (item.lastname ? ' ' + item.lastname : '')

        return {
          key: `person_${item.id}`,
          label: fullName,
          sortable: false,
          class: 'td-person-percentage'
        }
      })
    } catch (e) {
      console.log(e.message)
    }
  },
  computed: {
    fields () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table_title_incomes'), sortable: false, class: 'td-account' },
        ...this.persons,
        { key: 'total', label: this.$t('common.sum'), class: 'td-total text-right' }
      ]
    }
  },
  methods: {
    rowTotal (item) {
      const personReg = new RegExp('person_(\\d+)', 'i')
      const persons = Object.keys(item).filter((el) => personReg.test(el) === true)
      return persons.length > 0 ? persons.map((el) => item[el]).reduce((acc, el) => parseFloat(acc + el)) : 0
    },
    fetchHouseHolds () {
      return axios
        .get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons`)
        .then(response => response.data.data)
        .then(response => {
          return response
        })
        .catch(err => {
          console.error(err)
          return []
        })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      return item.status === 'error' ? 'bg-warning' : ''
    },
    saveHandler () {
      this.items = this.items.map((item) => {
        const total = Object.keys(item).filter((el) => el.startsWith('person_'))
          .map((el) => item[el])
          .reduce((acc, curr) => acc + curr)
        return Object.assign({}, item, {
          status: total !== 0 && (total < 100 || total > 100) ? 'error' : 'success',
          sum: total
        })
      })

      const failedStatus = this.items.filter((el) => el.status === 'error')

      if (failedStatus.length > 0) {
        this.$bvToast.toast(this.$t(this.translationPath + 'percentage_error_account_ownership').toString(), {
          title: this.$t(this.translationPath + 'account_ownership').toString(),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        return true
      }

      const postData = []
      const self = this
      this.items.forEach(function (item) {
        if (item.sum === 100) { // Only save the ones with 100 percent, all else will be deleted.
          Object.keys(item).filter((el) => el.startsWith('person_'))
            .map((el) => {
              const newProperty = {}
              newProperty.person_id = Number(el.replace('person_', ''))
              newProperty.percentage = item[el]
              newProperty.account_id = item.id
              newProperty.account_type = self.accountType
              postData.push(newProperty)
            })
        }
      })
      this.$emit('update', postData)
    }
  },
  watch: {
    data: {
      handler: function (val) {
        if (val) this.items = val
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-account-plan-v2-ownership-table{
  .account-plan-v2-ownership-table{
    .td-account{}
    .td-person-percentage{
      text-align: left;
      input{
        text-align: right;
        width:100px;
        padding:2px 5px 2px 10px;
        border: 1px solid rgb(203, 209, 216);
        margin: 2px;
        border-radius: 4px;
        font-weight: 400;
      }
    }
  }
}
</style>
