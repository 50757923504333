<template>
    <div>
        <b-tab
            :title="$t(translationPath + 'tab_income_overview')"
            :active="$route.name === 'account-plan-incomes-overview'"
            @click="clickHandler({ name: 'account-plan-incomes-overview' })"
        >
            <slot name="account-plan-incomes-overview"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_misc')"
          :active="$route.name === 'account-plan-incomes-misc'"
          @click="clickHandler({ name: 'account-plan-incomes-misc' })"
        >
          <slot name="account-plan-incomes-misc"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_match_to_globals')"
            :active="$route.name === 'account-plan-incomes-match-globals'"
            @click="clickHandler({ name: 'account-plan-incomes-match-globals' })"
        >
            <slot name="account-plan-incomes-match-globals"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_income_tink_match_incomes')"
            :active="$route.name === 'account-plan-incomes-tink'"
            @click="clickHandler({ name: 'account-plan-incomes-tink' })"
        >
            <slot name="account-plan-incomes-tink"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_account_split')"
            :active="$route.name === 'account-plan-incomes-ownership'"
            @click="clickHandler({ name: 'account-plan-incomes-ownership' })"
        >
            <slot name="account-plan-ownership-global"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_stats')"
            :active="$route.name === 'account-plan-incomes-stats'"
            @click="clickHandler({ name: 'account-plan-incomes-stats' })"
        >
            <slot name="account-plan-stats-incomes"></slot>
        </b-tab>
    </div>
</template>

<script>
export default {
  name: 'IncomeMenu',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  },
  computed: {
    checkDev () {
      return process.env.VUE_APP_NODE_ENV === 'development'
    }
  },
  methods: {
    clickHandler ({ name }) {
      if (this.$route.name === name) return true
      this.$router.push({ name })
    }
  }
}
</script>
