<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">
          {{ $t(translationPath + (currentUser.strict_accounting_mode ? "title_income_accounts" : "title_income_categories")) }}
        </h1>
      </b-row>
      <account-plan-submenu :active="currentActiveTab"></account-plan-submenu>

      <div class="wrapper_white_with_border pt-4 pb-2">
        <account-plan-incomes-header v-if="$route.name === 'account-plan-incomes-ownership'"></account-plan-incomes-header>
        <account-plan-expenses-header v-if="$route.name === 'account-plan-expense-ownership'"></account-plan-expenses-header>
        <account-plan-assets-header v-if="$route.name === 'account-plan-assets-ownership'"></account-plan-assets-header>
        <account-plan-liabilities-header v-if="$route.name === 'account-plan-liabilities-ownership'"></account-plan-liabilities-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <!-- INCOME -->
            <template v-if="$route.name === 'account-plan-incomes-ownership'">
              <IncomeMenu>
                <template slot="account-plan-ownership-global">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'income.ownership.description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsOwnershipTable
                    :data="items"
                    :busy="busy"
                    @update="handleSave"
                    accountType="income"
                  />
                </template>
              </IncomeMenu>
            </template>

            <!-- EXPENSE -->
            <template v-else-if="$route.name === 'account-plan-expense-ownership'">
              <ExpenseMenu>
                <template slot="account-plan-ownership-global">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'expense.ownership.description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsOwnershipTable
                    :data="items"
                    :busy="busy"
                    accountType="expense"
                    @update="handleSave"
                  />
              </template>
            </ExpenseMenu>
            </template>

            <!-- ASSET -->
            <template v-else-if="$route.name === 'account-plan-assets-ownership'">
              <AssetsMenu>
                <template slot="account-plan-ownership-global">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'asset.ownership.description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsOwnershipTable
                    :data="items"
                    :busy="busy"
                    @update="handleSave"
                    accountType="asset"
                  />
              </template>
            </AssetsMenu>
            </template>

            <!-- LIABILITY -->
            <template v-else-if="$route.name === 'account-plan-liabilities-ownership'">
              <LiabilityMenu>
                <template slot="account-plan-ownership-global">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'liability.ownership.description')"></div>
                    </b-col>
                  </b-row>
                  <AccountsOwnershipTable
                    :data="items"
                    :busy="busy"
                    accountType="liability"
                    @update="handleSave"
                  />
              </template>
            </LiabilityMenu>
            </template>
          </b-tabs>
        </b-card>
      </div>
      <!-- END: wrapper_white_with_border -->
    </div>
    <!-- END: main-content-wrapper -->
  </b-container>
</template>

<script>
import IncomeMenu from './submenu/IncomeMenu'
import ExpenseMenu from './submenu/ExpenseMenu'
import AssetsMenu from './submenu/AssetsMenu'
import LiabilityMenu from './submenu/LiabilityMenu'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import axios from 'axios'
import AccountsOwnershipTable from './AccountsOwnershipTable'
import AccountPlanIncomesHeader from './headers/AccountPlanIncomesHeader'
import AccountPlanExpensesHeader from './headers/AccountPlanExpensesHeader'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'
import AccountPlanLiabilitiesHeader from './headers/AccountPlanLiabilitiesHeader'

export default {
  name: 'AccountPlanIncomesOverview',
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  components: {
    IncomeMenu,
    ExpenseMenu,
    AssetsMenu,
    LiabilityMenu,
    AccountsOwnershipTable,
    AccountPlanIncomesHeader,
    AccountPlanExpensesHeader,
    AccountPlanAssetsHeader,
    AccountPlanLiabilitiesHeader
  },
  data () {
    return {
      items: [],
      currentActiveTab: 'incomes',
      busy: true
    }
  },
  async created () {
    let accountType
    if (this.$route.name === 'account-plan-expense-ownership') {
      accountType = 'expenses'
    } else if (this.$route.name === 'account-plan-assets-ownership') {
      accountType = 'assets'
    } else if (this.$route.name === 'account-plan-liabilities-ownership') {
      accountType = 'liabilities'
    } else {
      accountType = 'incomes'
    }
    this.currentActiveTab = accountType
  },
  methods: {
    async loadData () {
      this.busy = true
      const _self = this
      let accountType
      if (this.$route.name === 'account-plan-expense-ownership') {
        accountType = 'expense'
      } else if (this.$route.name === 'account-plan-assets-ownership') {
        accountType = 'asset'
      } else if (this.$route.name === 'account-plan-liabilities-ownership') {
        accountType = 'liability'
      } else {
        accountType = 'income'
      }
      try {
        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts-ownership?account_type=${accountType}`)
        this.items = response.data.data
          .map((el) => {
            return Object.assign({}, el, {
              title: _self.currentCOA.prefix_account_title_with_code ? el.title_with_code : el.title
            })
          })
      } catch (e) {
        console.log(e.message)
      }
      this.busy = false
    },
    async handleSave (value) {
      try {
        await axios.post(`${process.env.VUE_APP_ROOT_API}/accounts-ownership`, {
          ownership: value
        })
        this.$bvToast.toast(this.$t(this.translationPath + 'saved_account_ownership').toString(), {
          title: this.$t(this.translationPath + 'account_ownership').toString(),
          variant: 'success',
          solid: true,
          'auto-hide-delay': 3000
        })
        await this.loadData()
      } catch (e) {
        console.log(e.message)
        this.$bvToast.toast(this.$t(this.translationPath + 'saved_account_ownership_failed').toString(), {
          title: this.$t(this.translationPath + 'account_ownership').toString(),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
      }
    }
  },
  watch: {
    currentCOA: {
      handler: function () {
        /* if (newVal.prefix_account_title_with_code) {
          this.loadData()
        } */
        if (!this.items.length) {
          this.loadData()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import "./AccountPlan.scss";
</style>
