<template>
  <div>
    <b-row no-gutters>
      <b-col class="col-12 col-md-6 pl-3 pl-md-4">
        <h2 class="text-gray">{{ $t( translationPath + 'title_manage_income_categories') }}</h2>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AccountPlanIncomesHeader',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  }
}
</script>
